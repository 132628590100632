export default {
  firebase: {
    apiKey: 'AIzaSyCGbNwpapPS8fNSG_6LIjueWkyqhGd1pqs',
    authDomain: 'cloudnosys-saudi.firebaseapp.com',
    projectId: 'cloudnosys-saudi',
    storageBucket: 'cloudnosys-saudi.firebasestorage.app',
    messagingSenderId: '1045897830942',
    appId: '1:1045897830942:web:ada33e4f7d1128ef61440f',
    databaseURL: 'https://cloudnosys-saudi-default-rtdb.asia-southeast1.firebasedatabase.app',
    // databaseURL: 'https://cloudnosys-saudi.firebaseio.com',
  },
  api: {
    // explorer: 'https://us-central1-cloudnosys-prod.cloudfunctions.net/explorer',
    // playbooks: 'https://console.cloudnosys.com/runPlaybook',
  },
  masterOrg: 'cloudnosys-master_s0f8f',
  cloudnosysVersion: '2.1.3',
};

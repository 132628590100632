import axios from 'axios';
import { firebase } from '@/utils/firebase';

let { projectId } = firebase.apps[0].options;
const isValidProjectId =
  projectId === 'cloudnosysbeta' ||
  projectId === 'temp-cloudnosys' ||
  projectId === 'cloudnosys-prod';

const cloudFunctionsRegion = isValidProjectId ? 'us-central1' : '';
const proxyURL = `https://${cloudFunctionsRegion}-${projectId}.cloudfunctions.net/Graph-proxy`;

const proxyInstance = axios.create({
  baseURL: proxyURL,
  // baseURL: 'http://localhost:5000/temp-cloudnosys/us-central1/Graph-proxy', // local host
});

/**
 * Generic function to send requests through the proxy.
 * @param {string} targetUrl - The actual API endpoint to call.
 * @param {Object} params - Query parameters for the API.
 * @param {string} method - HTTP method (GET, POST, etc.).
 * @param {Object} data - Request body (for POST, PUT, etc.).
 */
export const callProxy = async (targetUrl, method = 'GET', params = {}, data = {}) => {
  try {
    const user = firebase.auth().currentUser;

    if (!user) {
      throw new Error('User is not authenticated');
    }
    const idToken = await user.getIdToken(true);

    let { projectId } = firebase.apps[0].options;

    const response = await proxyInstance({
      method,
      params: {
        target: targetUrl, // Target API URL passed to the proxy
        ...params, // Additional dynamic query parameters
      },
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
        // 'x-skip-auth': 'sk!p_@uth_012345', // to skip auth in the proxy
      },
      data,
    });
    return response.data;
  } catch (error) {
    console.error('Error calling proxy:', error.message);
    throw error;
  }
};

/**
 * Function to get all alerts by rule category.
 * @param {string} rulecategory - Dynamic rule category parameter.
 */
export const getGraphData = async params => {
  const { category, accountId } = params;
  const targetUrl = `http://54.169.110.129:8080/api/alerts/account?accountid=${accountId}&rulecategory=${category}`;
  return await callProxy(targetUrl, 'GET'); // Call through the proxy
};

export const getRuleGraph = async params => {
  const { resource_id, rule_id } = params;
  const targetUrl = `http://54.169.110.129:8080/api/getRuleGraph?resource_id=${resource_id}&rule_id=${rule_id}`;
  return await callProxy(targetUrl, 'GET'); // Call through the proxy
};

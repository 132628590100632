import React from 'react';
import { notification, Tag } from 'antd';
import { firebase, firebaseApp, firestore, functions } from '../utils/firebase';
import Axios from 'axios';

const port = 5001;
const projectId = 'temp-cloudnosys';
const isDevenv = process.env.NODE_ENV === 'development';
const isLocal = window.location.hostname === 'localhost';

const FUNC_NAMES = {
  PLAYBOOK_FUNC_NAMES: {
    getPlaybooksNodesConfig: 'Playbook-getPlaybookNodesConfig',
    runPlaybook: 'Playbook-runPlaybook',
    getPlaybookDataFromURL: 'Playbook-getPlaybookDataFromURL',
  },
  SIGNATURES: {
    startScan: 'Signatures-startScan',
  },
};

// ?? Turn true and false if you want to run locally, Note (Your server should be running in order to run locally)
const wannaRunLocal = false;

const runScanCall = async (cloudAccountData, advancedScanType = 'FULL_SCAN') => {
  try {
    const orgRef = firestore.collection('Organizations').doc(cloudAccountData.orgId);

    const cloudAccountRef = orgRef.collection('cloudAccounts').doc(cloudAccountData.id);

    const willScanRef = cloudAccountRef.collection('scanProgress').doc('willScan');

    const haveScanRef = cloudAccountRef.collection('scanProgress').doc('haveScan');

    const updates = [
      // Handle willScan document
      willScanRef.get().then(doc => {
        if (!doc.exists) {
          return willScanRef.set({ resources: 0 }, { merge: true }); // Create document
        } else {
          return willScanRef.update({ resources: 0 }); // Update existing document
        }
      }),

      // Handle haveScan document
      haveScanRef.get().then(doc => {
        if (!doc.exists) {
          return haveScanRef.set({ resources: 0 }, { merge: true }); // Create document
        } else {
          return haveScanRef.update({ resources: 0 }); // Update existing document
        }
      }),
      cloudAccountRef.update({
        status: 'start-scan',
        scanType: 'Full_Scan',
        updatedAt: new Date(),
        'cloudXrayConfig.scanType': advancedScanType,
      }),
    ];

    await Promise.all(updates);

    if (isDevenv && wannaRunLocal) {
      // ~ Development mode call functions locally
      const res = await Axios.post(
        `http://localhost:${port}/${projectId}/us-central1/${FUNC_NAMES.SIGNATURES.startScan}`,
        {
          data: cloudAccountData,
        }
      );
      return { ...res, data: res.data.result };
    }
    // ~ Live mode call functions from firebase
    return await functions.httpsCallable(FUNC_NAMES.SIGNATURES.startScan)(cloudAccountData);
  } catch (error) {
    console.log('Error from run scan call: ', error.message);
    throw error;
  }
};

export async function runScan(payload) {
  try {
    let { orgId, cloudAccount: cloudAccountArr, advancedScanType } = payload;
    let { projectId } = firebase.apps[0].options;

    notification.info({
      message: `Scan Started for ${
        cloudAccountArr?.length === 1 ? 'cloud account' : 'cloud accounts'
      }`,
      description: cloudAccountArr.map(item => (
        <Tag key={item.accountName} style={{ marginTop: '3px' }}>
          {item.accountName}
          {item.provider === 'AWS' ? (
            <img src="/awslogo.png" alt="aws" style={{ height: 18, marginBottom: '3px' }} />
          ) : item.provider === 'AZURE' ? (
            <img src="/azure@2x.png" alt="azure" style={{ height: 12, marginLeft: 7 }} />
          ) : item.provider === 'GCP' ? (
            <img
              src="/gcplogo.png"
              alt="gcp"
              style={{ height: 20, marginLeft: '-4px', marginRight: '-10px' }}
            />
          ) : null}
        </Tag>
      )),
    });

    // * Creating promises call for running scan
    const scanningPromises = [];
    for (let i = 0; i < cloudAccountArr.length; i++) {
      const element = cloudAccountArr[i];
      scanningPromises.push(runScanCall(element, advancedScanType));
    }

    await Promise.all(scanningPromises);
  } catch (error) {
    console.log('Error from run scan >> ', error.message);
  }
}

const axios = require('axios');

export const fetchCartographySignatures = async (cloudAccounts, cloudAccountDocId, orgId) => {
  // TODO: Make it dynamic URL]

  const url = 'http://3.0.188.198:8282/signatures/fetch';

  const data = {
    cloudAccounts,
    cloudAccountDocId,
    orgId,
  };

  try {
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

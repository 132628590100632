import prod from './prod-env-config';
import beta from './beta-env-config';
import playbook from './playbook-env-config';
import local from './local-env-config.js';
import selfHosted from './selfHosted-env-config.js';
import dev from './temp-env-config.js';
import preDev from './preDev-env-config.js';
import SA from './ksa-env-config.js';

let config;

switch (APP_ENV) {
  case 'dev':
    config = dev;
    break;
  case 'prod':
    config = prod;
    break;
  case 'beta':
    config = beta;
    break;
  case 'playbook':
    config = playbook;
    break;
  case 'local':
    config = local;
    break;
  case 'temp':
    config = dev;
    break;
  case 'selfHosted':
    config = selfHosted;
    break;
  case 'preDev':
    config = preDev;
    break;
  case 'SA':
    config = SA;
    break;
  default:
    config = dev;
    break;
}

export default config;
